import './bootstrap';
import { createApp, h } from 'vue';
import { createInertiaApp, Link, Head } from '@inertiajs/vue3';
import { MotionPlugin } from '@vueuse/motion'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import vuetify from './plugins/vuetify'
import { defineAsyncComponent } from 'vue'
import translationPlugin from './plugins/translationn'
import Toast from "vue-toastification";
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import timeago from 'vue-timeago3'
// Import the CSS or use your own!

// import VueTelInput from 'vue-tel-input';
import { createPinia } from 'pinia'
import { LinguaVue } from 'lingua-js'
import { Lingua } from './lingua'

const pinia = createPinia()
// pinia.use(
//     PiniaSharedState({
//         // Enables the plugin for all stores. Defaults to true.
//         enable: true,
//         // If set to true this tab tries to immediately recover the shared state from another tab. Defaults to true.
//         initialize: true,
//         // Enforce a type. One of native, idb, localstorage or node. Defaults to native.
//         type: 'native',
//     }),
// );
dayjs.extend(duration)

// // import { VueTelInput } from 'vue-tel-input';
const Dropdown = defineAsyncComponent(() => import('floating-vue').then(m => m.Dropdown));
const Tooltip = defineAsyncComponent(() => import('floating-vue').then(m => m.Tooltip));
const VueTelInput = defineAsyncComponent(() => import('vue-tel-input').then(m => m.VueTelInput));
const MapUrl = defineAsyncComponent(() => import("@/Components/MapUrl.vue"))
const LoadingButton = defineAsyncComponent(() => import("@/Components/Buttons/LoadingButton.vue"))
const VerticalNavMenuGroup = defineAsyncComponent(() => import("@/Components/AdminLayout/sidebar/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue"));
const HorizontalNavMenuGroup = defineAsyncComponent(() => import("@/Components/AdminLayout/sidebar/components/HorizontalNavMenuGroup.vue"));
const VerticalNavMenuLink = defineAsyncComponent(() => import("@/Components/AdminLayout/sidebar/components/vertical-nav-menu-link/VerticalNavMenuLink.vue"));
const HorizontalNavMenuLink = defineAsyncComponent(() => import("@/Components/AdminLayout/sidebar/components/HorizontalNavMenuLink.vue"));
const LineComponent = defineAsyncComponent(() => import("@/Components/AdminLayout/sidebar/components/vertical-nav-menu-link/LineComponent.vue"));
const TableVue = defineAsyncComponent(() => import("@/Components/Table/Table.vue"));
const FileInput = defineAsyncComponent(() => import("@/Components/Input/FileInput.vue"));

const NavButtonLink = defineAsyncComponent(() => import("@/Components/Buttons/NavButtonLink.vue"));
const TextInput = defineAsyncComponent(() => import("@/Components/Input/TextInput.vue"));
const TextareaInput = defineAsyncComponent(() => import("@/Components/Input/TextareaInput.vue"));

const TrashedMessage = defineAsyncComponent(() => import("@/Components/TrashedMessage.vue"));
const EmptyColumn = defineAsyncComponent(() => import("@/Components/Form/EmptyColumn.vue"));
const JetInput = defineAsyncComponent(() => import("@/Components/Input/Input.vue"));
const JetLabel = defineAsyncComponent(() => import("@/Components/Input/Label.vue"));

const QuillInput = defineAsyncComponent(() => import("@/Components/Input/QuillInput.vue"));
const Pagination = defineAsyncComponent(() => import("@/Components/Pagination.vue"));
const SearchFilter = defineAsyncComponent(() => import("@/Components/Input/SearchFilter.vue"));
const EditBtnVue = defineAsyncComponent(() => import("@/Components/Buttons/EditBtn.vue"));
const DeleteBtnVue = defineAsyncComponent(() => import("@/Components/Buttons/DeleteBtn.vue"));
const SwitchVue = defineAsyncComponent(() => import("@/Components/Switch/Switch.vue"));
const PageHeader = defineAsyncComponent(() => import("@/Components/LayoutItems/PageHeader.vue"));



const AdminLayout = defineAsyncComponent(() => import('./Layouts/AdminLayout.vue'));
const AppLayout = defineAsyncComponent(() => import('./Layouts/AppLayout.vue'));
const AuthLayout = defineAsyncComponent(() => import('./Layouts/AuthLayout.vue'));
const FullLayout = defineAsyncComponent(() => import('./Layouts/FullLayout.vue'));
// const appName = window.document.getElementsByTagName('title')[0]?.innerText || '3KODE';

// get app name from meta tag
const appName = window.document.querySelector('meta[name="app-name"]').getAttribute('content');


const ToastOptions = {
    transition: "Vue-Toastification__fade",
    maxToasts: 20,
    newestOnTop: true,
    position: "bottom-right",

};

createInertiaApp({
    progress: {
        delay: 250,

        // The color of the progress bar.
        color: '#5F5FFF',

        // Whether to include the default NProgress styles.
        includeCSS: true,

        // Whether the NProgress spinner will be shown.
        showSpinner: true,
    },
    title: (title) => `${appName} | ${title}`,
    resolve: async (name) => {
        const page = resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        );
        page.then((module) => {
            if (module.default.layout === undefined && (name.startsWith('Admin/') || name.startsWith('Profile/') || name.startsWith('Client/'))) {
                module.default.layout = AdminLayout
            }
            else if (module.default.layout === undefined && name.startsWith('Auth/')) {
                module.default.layout = AuthLayout
            }
            else if (module.default.layout === undefined && name.startsWith('Error')) {
                module.default.layout = FullLayout
            }
            else if (module.default.layout === undefined && name.startsWith('NewLanding') || name.startsWith('LmsLanding')) {
                module.default.layout = FullLayout
            }
            else if (module.default.layout === undefined && !name.startsWith('Auth/') && !name.startsWith('Admin/')) {
                module.default.layout = AppLayout
            }
            // module.default.layout = module.default.layout || DefaultLayoutFile;
        });
        return page;


    },

    setup({ el, App, props, plugin }) {

        const myApp = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(Toast, ToastOptions)
            // .use(VueTelInput)
            .use(pinia)
            .use(MotionPlugin)
            // .use(FloatingVue)
            .use(timeago, {
                converterOptions: {
                    includeSeconds: true,
                }
            })
            // .component('VueApexCharts', defineAsyncComponent(() =>
            //     import('vue3-apexcharts')
            // ))
            // .mixin(translations)

            .component('VDropdown', Dropdown)
            .component('VTooltip', Tooltip)
            // .component('VMenu', Menu)
            .component("VueTelInput", VueTelInput)
            .component("TableVue", TableVue)
            .component('VerticalNavMenuGroup', VerticalNavMenuGroup)
            .component('VerticalNavMenuLink', VerticalNavMenuLink)
            .component('HorizontalNavMenuGroup', HorizontalNavMenuGroup)
            .component('HorizontalNavMenuLink', HorizontalNavMenuLink)
            .component('LineComponent', LineComponent)
            .component('Link', Link)
            .component('inertia-link', Link)
            .component('file-input', FileInput)
            .component('Head', Head)
            .component('QuillInput', QuillInput)
            .component('Pagination', Pagination)
            .component('SearchFilter', SearchFilter)
            .component('EditBtnVue', EditBtnVue)
            .component('DeleteBtnVue', DeleteBtnVue)
            .component('SwitchVue', SwitchVue)
            .component('NavButtonLink', NavButtonLink)
            .component('TextInput', TextInput)
            .component('TextareaInput', TextareaInput)
            .component('TrashedMessage', TrashedMessage)
            .component('EmptyColumn', EmptyColumn)
            .component('JetInput', JetInput)
            .component('JetLabel', JetLabel)
            .component('MapUrl', MapUrl)
            .component('PageHeader', PageHeader)
            .component("loading-button", LoadingButton)
            .use(LinguaVue, {
                Lingua
            })
            .use(translationPlugin, {
                Lingua
            })

            .use(vuetify)
            // .use(CKEditor)
            .use(ZiggyVue)
            ;


        myApp.mount(el);
        // delete el.dataset.page;
        return myApp
    },
});


